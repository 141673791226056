var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-list",
                    { attrs: { shaped: "" } },
                    [
                      _c("v-subheader", [_vm._v("Invoice Sections")]),
                      _c(
                        "v-list-item-group",
                        { attrs: { color: "primary" } },
                        _vm._l(_vm.formSections, function (section, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function ($event) {
                                  return _vm.viewSection(i)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(section[1].name),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.formSections.length
                        ? _c(
                            "v-container",
                            [
                              _c("CustomFormFieldsSetup", {
                                model: {
                                  value: _vm.formSections[_vm.currentIndex][1],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formSections[_vm.currentIndex],
                                      1,
                                      $$v
                                    )
                                  },
                                  expression: "formSections[currentIndex][1]",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }